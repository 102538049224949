.accordion__item {
  background: rgba(29, 38, 59, 0.6);
  border: 2px solid rgba(79, 42, 206, 0.33);
  box-sizing: border-box;
  backdrop-filter: blur(30.9911px);
  border-radius: 32px; }

.accordion__math {
  width: 14px;
  height: 14px;
  position: relative; }
  .accordion__math:before, .accordion__math:after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: calc(50% - 1px);
    left: 0; }
  .accordion__math:after {
    transform: rotate(90deg); }
  .accordion__math.active:after {
    transform: rotate(0); }
