.gamesection {
  background-image: url("../images/gs-bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.gamesection-container {
  background: rgba(29, 38, 59, 0.6);
  border: 1.63111px solid rgba(79, 42, 206, 0.33);
  box-sizing: border-box;
  backdrop-filter: blur(30.9911px);
  border-radius: 32.6222px; }
  .gamesection-container .btn--purple {
    padding: 20px; }
  .gamesection-container ul li {
    position: relative; }
    .gamesection-container ul li::after {
      content: ' ';
      background: #4F2ACE;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      position: absolute;
      left: -20px;
      top: 5px;
      display: block; }
