.hamburger {
  height: 20px;
  width: 30px;
  display: none;
  cursor: pointer; }

.hamburger:before,
.hamburger:after,
.hamburger span {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 0; }

.hamburger:before {
  top: 0; }

.hamburger:after {
  bottom: 0; }

.hamburger span {
  top: calc(50% - 1px);
  opacity: 1; }

@media all and (max-width: 1023px) {
  .hamburger {
    display: block;
    @apply z-20; }
  .menu {
    background-color: #08121E;
    right: -40%;
    transition: right 0.5s ease;
    -webkit-transition: right 0.5s ease;
    position: absolute;
    top: 0;
    width: 40%;
    height: 100vh;
    z-index: 10;
    padding-top: 110px;
    padding-bottom: 50px; }
  .menu ul {
    width: 100%; }
  .active .menu {
    right: 0; }
  .active .hamburger:before,
  .active .hamburger:after {
    background-color: #fff; }
  .active .hamburger:before {
    top: calc(50% - 1px);
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }
  .active .hamburger:after {
    bottom: calc(50% - 1px);
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  .active .hamburger span {
    opacity: 0; } }

@media all and (max-width: 767px) {
  .menu {
    right: -80%;
    width: 80%;
    padding-top: 85px; } }
