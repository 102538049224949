@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Inter';
  src: url("@assets/fonts/Inter/Inter-Regular.woff2") format("woff2"), url("@assets/fonts/Inter/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Inter-900';
  src: url("@assets/fonts/Inter/Inter-Black.woff2") format("woff2"), url("@assets/fonts/Inter/Inter-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Inter-800';
  src: url("@assets/fonts/Inter/Inter-ExtraBold.woff2") format("woff2"), url("@assets/fonts/Inter/Inter-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Inter-700';
  src: url("@assets/fonts/Inter/Inter-Bold.woff2") format("woff2"), url("@assets/fonts/Inter/Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Inter-500';
  src: url("@assets/fonts/Inter/Inter-Medium.woff2") format("woff2"), url("@assets/fonts/Inter/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Inter-300';
  src: url("@assets/fonts/Inter/Inter-Light.woff2") format("woff2"), url("@assets/fonts/Inter/Inter-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Inter-600';
  src: url("@assets/fonts/Inter/Inter-SemiBold.woff2") format("woff2"), url("@assets/fonts/Inter/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Inter-200';
  src: url("@assets/fonts/Inter/Inter-ExtraLight.woff2") format("woff2"), url("@assets/fonts/Inter/Inter-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Inter-100';
  src: url("@assets/fonts/Inter/Inter-Thin.woff2") format("woff2"), url("@assets/fonts/Inter/Inter-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

.btn {
  padding: 10px 35px;
  font-family: 'Inter-500';
  border-radius: 70px; }
  .btn--purple {
    background: #4F2ACE; }
  .btn--pink {
    background: #912A7F; }
  .btn--black {
    background: #08121E; }
  .btn--small {
    padding: 20px 64px; }
  .btn--border {
    border: 1px solid #7046ff; }
  @media all and (max-width: 1280px) {
    .btn--small {
      padding: 18px 45px; } }

.wrapper {
  padding-left: 7.5%;
  padding-right: 7.5%;
  max-width: 1440px;
  margin: 0 auto; }

.check li {
  display: flex;
  font-family: 'Inter-500';
  @apply mb-4; }
  .check li:before {
    content: '';
    background-image: url("@assets/images/check.svg");
    height: 21px;
    width: 21px;
    margin-top: 3px;
    background-position: center;
    background-size: 21px 20px;
    margin-right: 10px; }
